import React from "react";

const SlideOverCompanyMembers = ({companyInfo}) => {

  const roleName = {
    200: 'Observateur',
    210: 'Responsable',
    215: 'Directeur',
    225: 'Admin'
  }

  return (
    <>
      {
        companyInfo?.employees?.length > 0 && (
          companyInfo?.employees?.map((item, key) => (
            <div key={key} className={'border rounded p-3'}>
              <div>{roleName[item.role]}</div>
              <div className={'text-gray-500'}>{item.firstname} {item.lastname}</div>
              <div className={'text-gray-500 flex gap-1'}>
                <a href={`mailto:${item.email}`} target={'_blank'}>{item.email}</a>
                {
                  item.phone && (
                    <>
                      <div>·</div>
                      <a href={`tel:${item.phone}`} target={'_blank'}>{item.phone}</a>
                    </>
                  )
                }
              </div>
            </div>
          ))
        )
      }
    </>
  )
}

export default SlideOverCompanyMembers;