import {ChevronRight, CrossIcon} from "../../../../../assets/icons";
import React, {useEffect, useState} from "react";
import UseContext from "../../../../../hooks/useContext";

const SlideOverCompanyRemunerationConditions = ({companyInfo, setIsShowCoeff, setIsCoeffSend}) => {
  const [isCoeffCompleted, setIsCoeffCompleted] = useState(false)

  useEffect(() => {
    if (companyInfo?.coefficients) {
      if (companyInfo?.coefficients?.length > 0) {
        for (let i = 0; i < companyInfo?.coefficients.length; i++) {
          if (companyInfo?.coefficients[i]?.management === null || companyInfo?.coefficients[i]?.delegation === null) {
            setIsCoeffCompleted(false)
            break
          } else {
            setIsCoeffCompleted(true)
            setIsCoeffSend(true)
          }
        }
      } else {
        setIsCoeffCompleted(false)
      }
    } else {
      setIsCoeffCompleted(false)
    }
  }, [companyInfo])

  return (
    <>
      <button type={'button'} className={'text-left border rounded p-3 mb-5'}
              onClick={() => setIsShowCoeff(true)}>
        <div className={'flex justify-between'}>
          <div className={'my-auto'}>
            <div>Coefficient de facturation</div>
            {
              !isCoeffCompleted && (
                <div className={'text-gray-500 flex gap-2'}>
                  <div className={'my-auto'}>
                    <CrossIcon wh={20} color={'#EF4444'}/>
                  </div>
                  <div className={'my-auto'}>
                    La page n'est pas complète
                  </div>
                </div>
              )
            }
          </div>
          <div className={'my-auto'}>
            <ChevronRight wh={24} color={'#111827'}/>
          </div>
        </div>
      </button>
    </>
  )
}

export default SlideOverCompanyRemunerationConditions;