import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import Button from "../../../../button";
import ModalFooter from "../../../components/footer";
import ApiDatabase from "../../../../../server";
import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import React, {useEffect, useState} from "react";
import StyledInput from "../../../../styledInput/StyledInput";

const ModalEditDescriptionCompany = () => {
  const {toggle} = useModal();
  const {objID, setObjID, setModalDataReturn} = UseContext()
  const [company, setCompany] = useState({})
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isWaiting, setIsWaiting] = useState(false)
  const [errorDescription, setErrorDescription] = useState('')

  const token = localStorage.getItem('xsrfToken')

  useEffect(() => {
    if (objID) {
      setIsWaiting(true)
      ApiDatabase.getDescriptionCompany({token, idCompany: objID}, (data) => {
        setCompany(data.company)
        setDescription(data.company?.description)
        setIsWaiting(false)
      }, (err) => {
        if (err.name !== "CanceledError") {
          console.error(err);
          setIsWaiting(false)
        }
      })
    }
  }, [objID]);

  const handleSubmit = (e) => {
    setErrorDescription('')
    if (company && description === company.description) {
      setErrorDescription('Aucune modification de la description')
      return;
    }
    if (description.length < 5) {
      setErrorDescription('La description fait moins de 5 caractères')
      return;
    }

    setIsLoading(true);

    ApiDatabase.postDescriptionCompany({token, idCompany: objID, description}, (data) => {
      setIsLoading(false);
      setObjID('')
      setModalDataReturn({description})
      toggle();
    }, (err) => {
      if (err.name !== "CanceledError") {
        console.error(err);
        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Brief de l'entreprise
      </Modalheader>
      <ModalBody>
        {isWaiting ? (
          <div className={'animate-pulse'}>
            <div className={'flex flex-col gap-6'}>
              <div className={'text-gray-500 text-sm flex gap-1'}>
                <div className="w-56 h-3 bg-gray-200 rounded"></div>
              </div>
              <div className={'flex flex-col gap-1'}>
                <div className="w-full h-40 bg-gray-200 rounded"></div>
                <div className="w-44 h-3 bg-gray-200 rounded"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className={'flex flex-col gap-6'}>
            <div className={'text-gray-500 text-sm flex gap-1'}>
              Ajoutez un brief pour l'entreprise <div className={'font-bold'}>{company.name_commercial}.</div>
            </div>
            <div className={'flex flex-col'}>
              <StyledInput
                type={"textarea"}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value)
                }}
                placeholder={'Exemple de text'}
                error={errorDescription}
              />
              <div className={'text-gray-500 text-sm'}>5 caractères minimum</div>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {isLoading ? (
          <>
            <Button size={'LG'} color={'SECONDARY'}>Fermer</Button>
            <Button size={'LG'} color={'DISABLED'}>Confirmation ...</Button>
          </>

        ) : (
          <>
            <Button size={'LG'} color={'SECONDARY'} onClick={toggle}>Fermer</Button>
            <Button size={'LG'} color={'PRIMARY'} onClick={handleSubmit}>Confirmer</Button>
          </>
        )}
      </ModalFooter>
    </>
  )
}

export default ModalEditDescriptionCompany;
