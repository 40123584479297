import Button from "../../../../button";
import {Close, Pencil} from "../../../../../assets/icons";
import React, {useEffect, useState} from "react";
import ApiDatabase from "../../../../../server";
import {toast} from "react-toastify";
import UseContext from "../../../../../hooks/useContext";
import useModal from "../../../../modal/useModal";
import TextSelectMultipleBigAccount from "../../../../textSelectMultiple/textSelectMultipleBigAccount";

const SlideOverCompanyOverview = ({companyInfo, setCompanyInfo}) => {
  const {objID, setObjID, modalDataReturn, setModalDataReturn, refreshCompanyBigAccount} = UseContext()
  const [isCostEstimateDisplay, setIsCostEstimateDisplay] = useState(false)
  const [bigAccount, setBigAccount] = useState('')
  const {toggle} = useModal()
  const token = localStorage.getItem('xsrfToken')

  useEffect(() => {
    ApiDatabase.getDisplayCostEstimate({token: token, idCompany: objID}, (data) => {
      setIsCostEstimateDisplay(data?.displayCostEstimate)
    }, err => {
      if (err.name !== "CanceledError") {
        console.error(err)
      }
    })
  }, [])

  const manageDisplayCostEstimate = () => {
    ApiDatabase.putDisplayCostEstimate({
      token: token,
      idCompany: objID,
      displayCostEstimate: !isCostEstimateDisplay
    }, (data) => {
      setIsCostEstimateDisplay(!isCostEstimateDisplay)
      toast.success('Les estimations de coût sont maintenant visible par le client')
    }, (err) => {
      if (err.name !== "CanceledError") {
        console.error(err)
      }
    })
  }

  const editDescriptionCompany = () => {
    setObjID(companyInfo?._id)
    toggle('edit_description_company')
  }

  useEffect(() => {
    if (modalDataReturn?.description) {
      setCompanyInfo((prevData) => ({
        ...prevData,
        description: modalDataReturn.description
      }));

      setModalDataReturn({});
    }
  }, [modalDataReturn]);

  useEffect(() => {
    ApiDatabase.getCompanyBigAccount({token: token, idCompany: objID}, (data) => {
      setBigAccount(data?.bigAccount ?? '')
    }, err => {
      if (err.name !== "CanceledError") {
        console.error(err)
      }
    })
  }, [refreshCompanyBigAccount]);

  const deleteCompanyBigAccount = () => {
    ApiDatabase.deleteCompanyBigAccount({token: token, idCompany: objID}, (data) => {
      toast.success('Le grand compte a bien été supprimé')
      setBigAccount('')
    }, error => {
      toast.error(`Une erreur est survenue lors de la suppression du grand compte ${error.response.data.message}.`)
    })
  }

  return (
    <>
      <div className={''}>
        <div className={'border rounded p-3 flex justify-between gap-2'}>
          <div className={'my-auto'}>
            Affichage des estimations de coût du mois en cours
          </div>
          <div className={'my-auto'}>
            <input type="checkbox" checked={isCostEstimateDisplay}
                   onChange={() => manageDisplayCostEstimate()}/>
          </div>
        </div>
      </div>
      <div className={''}>
        <div className={'border rounded p-3 flex justify-between items-center gap-2'}>
          <div className={'flex flex-col'}>
            <div>Brief de l'entreprise</div>
            {companyInfo?.description && <div className={'text-gray-500'}>{companyInfo.description}</div>}
          </div>
          <div className={''}>
            <Button size={'ICON'} color={'ICON'} onClick={() => editDescriptionCompany()}>
              <Pencil wh={20} color={'#374151'}/>
            </Button>
          </div>
        </div>
      </div>
      <div className={''}>
        <div className={'border rounded p-3 flex justify-between gap-2'}>
          <div className={'my-auto w-full'}>
            Associé au groupe :
          </div>
          <div className={'my-auto w-full'}>
            {
              bigAccount ? (
                <div className={'p-3 border rounded'}>
                  <div className={'flex justify-between'}>
                    <div>
                      <div>{bigAccount?.name}</div>
                      <div className={'text-gray-500 text-sm'}>
                        le {bigAccount?.companies.find(item => item.id_company === objID)?.date && new Date(bigAccount?.companies.find(item => item.id_company === objID)?.date).toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                      </div>
                    </div>
                    <div>
                      <div className={'cursor-pointer p-1 border rounded'}
                           onClick={() => deleteCompanyBigAccount()}>
                        <Close wh={18} color={'#374151'}/>
                      </div>
                    </div>
                  </div>
                </div>
              ) : <TextSelectMultipleBigAccount/>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SlideOverCompanyOverview;